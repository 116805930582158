import React from "react";
import { graphql } from "gatsby";

import DefaultLayout from "../layouts/default";
import NewsContent from "../components/NewsContent";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BackLink from "../components/BackLink";

interface NewsQueryProps {
  data: {
    cms: {
      entry: {
        title: string;
        inhalt: string;
        postDate: string;
      };
    };
  };
}

export const NewsQuery = graphql`
  query ($uri: String!) {
    cms {
      entry(uri: [$uri]) {
        title
        postDate

        ... on CMS_news_news_Entry {
          inhalt
        }
      }
    }
  }
`;

const News: React.FC<NewsQueryProps> = ({ data }) => {
  const entry = data.cms.entry;

  const date = new Date(entry.postDate);
  const dateOptions = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };

  return (
    <DefaultLayout title={entry.title + " – News"}>
      <Header />
      <div
        className="wrapper has-padding-top has-padding-bottom newsContent"
        style={{ maxWidth: 900 }}
      >
        <p className="is-color-grey">
          {date.toLocaleDateString("de-CH", dateOptions)}
        </p>
        <h1>{entry.title}</h1>

        <NewsContent content={entry.inhalt} />
        <BackLink />
      </div>
      <Footer breadcrumb={entry.title} />
    </DefaultLayout>
  );
};

export default News;
